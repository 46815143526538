<template>
  <div class="CategoryListingBlock" :class="desktopWidth('full')">
    <div v-editable="blok">
      <component
        :is="uiStore.isMobile ? SideScroll:'div'"
        v-if="blok.design === 'buttons'"
      >
        <div class="flex gap-12 mobOnly:pl-12 desk:flex-wrap">
          <nuxt-link
            v-for="item in blok.listItems"
            :key="item._uid"
            :to="itemLink(item)"
          >
            <div class="btn btn--secondary"> {{ item.category }}</div>
          </nuxt-link>

          <div class="sideScrollRightPad desk:hidden" />
        </div>
      </component>
      <div
        v-if="(blok.swiperMobile && isMobile) || (itemCount > 8)"
        class="relative"
        :class="{
          'hidden' : blok.design === 'buttons',
        }"
      >
        <div
          v-if="blok.design === 'logo-images'"
          class="flex flex-col items-center w-full mb-24"
        >
          <div class="w-1/2 desk:w-1/3">
            <nuxt-img
              v-if="blok.logoImage && blok.logoImage.filename"
              preset="standard"
              loading="lazy"
              sizes="sm:100vw desk:1260px"
              class="object-cover h-full w-full"
              :src="blok.logoImage.filename"
              :alt="blok.logoImage.alt"
            />
          </div>
          <nuxt-link
            v-if="blok.buttonText"
            :to="$sbLinkFix(blok.link)"
            :target="$sbLinkTarget(blok.link)"
          >
            <div class="btn btn--secondary"> {{ blok.buttonText }}</div>
          </nuxt-link>
        </div>
        <client-only>
          <Swiper
            class="swiper-cover desk:!overflow-hidden !overflow-visible w-full desk:!pb-40 !pb-32"
            :modules="[SwiperNavigation, SwiperPagination, SwiperA11y]"
            :space-between="8"
            :pagination="{
              clickable: true,
              dynamicBullets: true,
            }"
            :navigation="{
              nextEl: `.swiper-button-next.swiper-for-blok-${blok._uid}`,
              prevEl: `.swiper-button-prev.swiper-for-blok-${blok._uid}`,
            }"

            :loop="false"
            :slides-per-view="uiStore.isMobile ? 2.1 : 8"
            :no-swiping="true"
          >
            <SwiperSlide
              v-for="item in blok.listItems"
              :key="item._uid"
              class="flex flex-col items-center mb-24 desk:mb-32 deskLarge:!overflow-hidden desk:cursor-grab desk:active:cursor-grabbing"
            >
              <nuxt-link
                :to="itemLink(item)"
              >
                <div class="flex flex-col items-center">
                  <div class="relative mb-12">
                    <nuxt-img
                      v-if="item.image && item.image.filename"
                      preset="standard"
                      loading="lazy"
                      sizes="sm:50vw desk:153px"
                      class="aspect-landscape object-cover h-full w-full"
                      :src="item.image.filename"
                      :alt="item.image.alt"
                    />
                    <div
                      v-if="item.flagText"
                      class="absolute top-8 left-8 px-8 pt-4 pb-2 type-headline-xxs bg-brightGreen"
                    >
                      {{ item.flagText }}
                    </div>
                  </div>
                  <div class="type-headline-xs underline decoration-darkest/20">
                    {{ item.category }}
                  </div>
                </div>
              </nuxt-link>
            </SwiperSlide>
          </Swiper>
        </client-only>
        <div class="hidden desk:block">
          <div
            class="swiper-button-prev !top-[60px]"
            :class="{[`swiper-for-blok-${blok._uid}`]: true}"
          />
          <div
            class="swiper-button-next !top-[60px]"
            :class="{[`swiper-for-blok-${blok._uid}`]: true}"
          />
        </div>
      </div>
      <div v-else class="flex flex-col desk:flex-row gap-12">
        <div
          v-if="blok.design === 'logo-images'"
          class="flex flex-col items-center desk:items-start w-full mb-24 desk:mb-0"
        >
          <div class="w-1/2 desk:w-1/3">
            <nuxt-img
              v-if="blok.logoImage && blok.logoImage.filename"
              preset="standard"
              loading="lazy"
              sizes="sm:100vw desk:1260px"
              class="object-cover h-full w-full"
              :src="blok.logoImage.filename"
              :alt="blok.logoImage.alt"
            />
          </div>
          <nuxt-link
            v-if="blok.buttonText"
            :to="$sbLinkFix(blok.link)"
            :target="$sbLinkTarget(blok.link)"
          >
            <div class="btn btn--secondary"> {{ blok.buttonText }}</div>
          </nuxt-link>
        </div>
        <div
          class="w-full"
          :class="{
            'grid grid-cols-2 desk:grid-cols-8 gap-x-12 gap-y-24 desk:gap-y-32' : blok.design === 'images' || blok.design === 'images-center',
            'grid desk:flex grid-cols-2 gap-x-12 gap-y-24 desk:gap-y-32 justify-center' : blok.design === 'images-center',
            'grid grid-cols-2 desk:grid-cols-4 gap-x-12 gap-y-24 desk:gap-y-32' : blok.design === 'logo-images',
            'flex flex-wrap gap-12' : blok.design === 'buttons',
          }"
        >
          <nuxt-link
            v-for="item in blok.listItems"
            :key="item._uid"
            :to="itemLink(item)"
            :class="{
              centeredImage: blok.design === 'images-center'
            }"
          >
            <div v-if="blok.design === 'buttons'" class="hidden" />
            <div v-else class="flex flex-col items-center">
              <div class="relative mb-12">
                <nuxt-img
                  v-if="item.image && item.image.filename"
                  preset="standard"
                  loading="lazy"
                  sizes="sm:50vw desk:153px"
                  class="aspect-landscape object-cover h-full w-full"
                  :src="item.image.filename"
                  :alt="item.image.alt"
                />
                <div
                  v-if="item.flagText"
                  class="absolute top-8 left-8 px-8 pt-4 pb-2 type-headline-xxs bg-brightGreen"
                >
                  {{ item.flagText }}
                </div>
              </div>
              <div class="type-headline-xs underline decoration-darkest/20">
                {{ item.category }}
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StoryBlokPropBase, StoryBlokAsset, StoryBlokLink, ProductSelector } from '~/constants/types/storyblok';

import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import SideScroll from '~/components/globals/SideScroll.vue';
import { useGlobalContentStore } from '~/store/globalContent';
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);
const { $sbLinkFix, $sbLinkCheck } = useNuxtApp();
const globalContent = useGlobalContentStore();

type ListItem = StoryBlokPropBase & {
  category: string;
  image: StoryBlokAsset;
  link: StoryBlokLink;
  flagText: string;
  productLink: ProductSelector[]
}

type Props = StoryBlokPropBase & {
  swiperMobile: boolean;
  design: 'images' | 'images-center' | 'logo-images' | 'buttons',
  logoImage: StoryBlokAsset,
  buttonText: string,
  link: StoryBlokLink,
  listItems: ListItem[];
}

const props = defineProps<{
  blok: Props,
}>();

const { desktopWidth } = useDesktopWidth(props);

const itemCount = computed(() => {
  if (!isMobile.value && (props.blok.design === 'images' || props.blok.design === 'images-center')) {
    return props.blok.listItems.length;
  } else {
    return 0;
  }
});

const itemLink = (item: ListItem) => {

  if (item.productLink &&
    item.productLink[0]?.product &&
    typeof item.productLink[0].product !== 'string' &&
    item.productLink[0].product.product !== null) {
    return `${globalContent.getPrependPathForManualLinks}/${globalContent.getProductBasePath}/${item.productLink[0].product.product.url}`;
  }
  if ($sbLinkCheck(item.link)) {
    return $sbLinkFix(item.link);
  }
  return null;
};

</script>
<style scoped lang="postcss">
.swiper-button-next {
  @apply !-right-16;
}
.swiper-button-prev {
  @apply !-left-16;
}

.centeredImage {
  @apply basis-[153px] shrink-0 grow-0;
}
</style>
